import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { InputSubmit } from "../../atoms/input-submit";
import "./style.scss";

import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
const { CONFIG_APP_KEY_GIPHY } = require(`../../../shared/constants`);
const giphyFetch = new GiphyFetch(CONFIG_APP_KEY_GIPHY);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "#0D532D" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const SelectGiphModal = ({
  btnLabel,
  setModalGif = () => {},
  modalGif,
  id = "",
  type = "",
  setText = () => {},
  searchGiphText,
  defaultKeyword,
}) => {
  const [open, setOpen] = React.useState(false);
  const [textforSearch, set_textforSearch] = useState("");

  useEffect(() => {
    if (defaultKeyword) {
      set_textforSearch(defaultKeyword);
    }
  }, [open]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const GridDemo = ({ onGifClick, searchFor }) => {
    const fetchGifs = (offset) => {
      if (searchFor) {
        return giphyFetch.search(searchFor, { offset, limit: 10 });
      } else return giphyFetch.trending({ offset, limit: 10 });
    };
    const [width, setWidth] = useState(700);

    const Overlay = ({ gif, isHovered }) => {
      return <div className="overlay">{(isHovered = false)}</div>;
    };
    return (
      <>
        <Grid onGifClick={onGifClick} fetchGifs={fetchGifs} width={width} columns={3} overlay={Overlay} />
        {/* <ResizeObserver
          onResize={({ width }) => {
            setWidth(width);
          }}
        /> */}
      </>
    );
  };
  return (
    <>
      {type === "SelectGif" ? (
        <InputSubmit
          buttonType="button"
          buttonAction={handleClickOpen}
          buttonLabel={btnLabel}
          icon={require("../../../assets/img/icons/plus.png")}
        />
      ) : (
        <InputSubmit
          buttonType="button"
          buttonAction={handleClickOpen}
          icon={require("../../../assets/img/icons/plus.png")}
        />
      )}

      <Dialog
        className="modal_lg"
        onClose={() => {
          handleClose();
          set_textforSearch("");
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="model-dialog">
          <DialogTitle className="diagraammmm" id="customized-dialog-title" onClose={handleClose} />
          <MuiDialogContent>
            <div className={type != "SelectGif" ? "model-header hideCursor" : "model-header"}>
              <input
                className="form-control"
                placeholder="Search"
                value={textforSearch}
                onChange={(e) => {
                  set_textforSearch(e.target.value);
                }}
              ></input>

              {type != "SelectGif" && (
                <button
                  onClick={() => {
                    setText(textforSearch);
                    handleClose();
                  }}
                  class="btn btn-lg btn-primary"
                >
                  Done
                </button>
              )}
            </div>

            <GridDemo
              type={type}
              searchFor={textforSearch}
              type={type}
              onGifClick={(gif, e) => {
                e.preventDefault();
                if (type != "SelectGif") {
                  return;
                } else {
                  e.preventDefault();
                  modalGif &&
                    modalGif.map((item, index) => {
                      if (index == id) {
                        item.url = gif.images.original.url;
                        setModalGif((state) => [...state]);
                      }
                    });

                  setOpen(false);
                }
              }}
            />
          </MuiDialogContent>
        </div>
      </Dialog>
    </>
  );
};
