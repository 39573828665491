const { VALIDATION_MESSAGES, EMAIL_REGX } = require(`../../../../shared/constants`)
const triviaValidator = values => {
    const errors = {};
    if (!values['announcement']) {
      errors['announcement'] =
        VALIDATION_MESSAGES.ANNOUCEMENT_REQUIRED;
    } 
    if (!values['answerDuration']) {
      errors['answerDuration'] =
        VALIDATION_MESSAGES.ANSWER_DURATION_REQUIRED;
    }
    if (!values['answer']) {
      errors['answer'] =
        VALIDATION_MESSAGES.ANSWER_REQUIRED;
    }
    if (!values['announcementDuration']) {
      errors['announcementDuration'] =
        VALIDATION_MESSAGES.DURATION_REQUIRED;
    }
    return errors;
  };
  export default triviaValidator;