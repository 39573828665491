import React from "react";
import classNames from "classnames";
// import next from "../../../assets/next.png";

export const InputSubmit = ({
  buttonLabel,
  buttonStyle,
  disabled = false,
  buttonType = "submit",
  loginText = "Log In",
  buttonAction = () => {},
  containerStyle,
  icon,
}) => {
  return (
    <button
      disabled={disabled}
      type={buttonType}
      onClick={(e) => {
        buttonAction();
      }}
      className={
        icon && buttonLabel
          ? classNames(["btn btn-lg btn-primary btn-radius"])
          : classNames(["btn mr-1 btn-sm btn-secondary"])
      }
    >
      {icon && <img src={icon} alt="imageegege" width="20" />}
      {buttonLabel} {buttonLabel === loginText && <i></i>}
    </button>
  );
};
