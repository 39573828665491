import { takeLatest, all, put } from "redux-saga/effects";

import {
    ADD_ANNOUNCEMENT,addAnnouncementData,stopLoader,startLoader,setAuthorization,GET_ANNOUNCEMENT_LISTING,
    DELETE_ANNOUNCMENT_DATA,EDIT_ANNOUNCMENT_DATA
} from '../actions';

const api = require(`../../shared/api`);
const { updateAuthToken, postRequestNoAuth, postRequest, getRequest, deleteRequest, putRequest } = require(`../../helpers`);
const { STATUS_CODE } = require(`../../shared/constants`);

function* addAnnouncement({ data, success, onError }) {
    try {
        yield put(startLoader())
        const response = yield postRequest({ API: `${api.URL.ADD_ANNOUNCEMENT_API}`, DATA: data });
        if (window.navigator.onLine === false) {
            yield put(stopLoader())
            onError({
                msg: 'You appear to be offline. Please check your connection.'
            })
        } else {
            if (response.status === STATUS_CODE.unAuthorized) {
                yield put(setAuthorization(null));
                return;
            }
            if (response.status !== STATUS_CODE.successful) {
                onError(response.data);
                yield put(stopLoader());
            }
            else {
               
                success(response.data);
                yield put(stopLoader());
            }
        }
    }
    catch (error) {
        return;
    }
}

function* listAnnouncements({ data, success, onError }) {
    try {
        console.log(data,'id')
        yield put(startLoader())
        const response = yield getRequest({ API: `${api.URL.LIST_ALL_ANNOUNCEMENT}/${data}`});
        if (window.navigator.onLine === false) {
            yield put(stopLoader())
            onError({
                msg: 'You appear to be offline. Please check your connection.'
            })
        } else {
            if (response.status === STATUS_CODE.unAuthorized) {
                yield put(setAuthorization(null));
                return;
            }
            if (response.status !== STATUS_CODE.successful) {
                onError(response.data);
                yield put(stopLoader());
            }
            else {
                console.log(response.data,'stop loader')
                yield put(addAnnouncementData(response.data.data));
              
                yield put(stopLoader());
               
            }
        }
    }
    catch (error) {
        return;
    }
}

function* deleteAnnouncements({ data, success, onError }) {
    try {
        console.log(data,'id')
        yield put(startLoader())
        const response = yield deleteRequest({ API: `${api.URL.DELETE_ANNOUNCEMENT}/${data}`});
        if (window.navigator.onLine === false) {
            yield put(stopLoader())
            onError({
                msg: 'You appear to be offline. Please check your connection.'
            })
        } else {
            if (response.status === STATUS_CODE.unAuthorized) {
                yield put(setAuthorization(null));
                return;
            }
            if (response.status !== STATUS_CODE.successful) {
                onError(response.data);
                yield put(stopLoader());
            }
            else {
                console.log(response)
                success(response.data);
                yield put(stopLoader());
            }
        }
    }
    catch (error) {
        return;
    }
}

function* editAnnouncements({ data, success, onError }) {
    try {
        yield put(startLoader())
        const response = yield putRequest({ API: `${api.URL.EDIT_ANNOUNCEMENT}/${data.id}`, DATA:data});
        if (window.navigator.onLine === false) {
            yield put(stopLoader())
            onError({
                msg: 'You appear to be offline. Please check your connection.'
            })
        } else {
            if (response.status === STATUS_CODE.unAuthorized) {
                yield put(setAuthorization(null));
                return;
            }
            if (response.status !== STATUS_CODE.successful) {
                onError(response.data);
                yield put(stopLoader());
            }
            else {
                success(response.data);
                yield put(stopLoader());
            }
        }
    }
    catch (error) {
        return;
    }
}

function* AnnouncementSaga() {
    yield all([
        takeLatest(ADD_ANNOUNCEMENT, addAnnouncement),
        takeLatest(GET_ANNOUNCEMENT_LISTING, listAnnouncements),
        takeLatest(DELETE_ANNOUNCMENT_DATA, deleteAnnouncements),
        takeLatest(EDIT_ANNOUNCMENT_DATA, editAnnouncements),
    ]);
}

export default AnnouncementSaga;
