import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import './style.scss';
export const Select = ({
  defaultValue,
  label,
  config = {},
  meta: { touched, error },
  options,
  widthStyle,
  input,
  data,
  onValueChange = () => { },
  isSearchable = false,
  placeholder,
  // defaultMenuIsOpen,
  value
}) => {
  widthStyle = widthStyle ? widthStyle : "";
  const validationSpan =
    touched && error ? (
      <span className="error_msg text-danger">{error}</span>
    ) : null;

  const [selectValue, setSelectvalue] = useState('')

  useEffect(() => {
    if (options) {
      let obj = options.filter(option => option.value === config.value)
      setSelectvalue(obj)
    }

  }, [config.value && options])
  const [inputClass,setInputClass]  = useState("form-group")

  useEffect(() =>{
    if(selectValue != ''){
      setInputClass("form-group floating-input")
    } else {
      setInputClass("form-group")
    }
  },[selectValue])
  return (
    <>
  
      <div className={inputClass}>
        <ReactSelect
          {...input}
          {...config}
          options={options}
          // onChange={onValueChange}
          isSearchable={isSearchable}
          placeholder={placeholder ? placeholder : ''}
          value={selectValue}
          className="form-select"
          onChange={(value) => {
            input.onChange(value);
            onValueChange(value)
            setSelectvalue(value)
          }}
          onBlur={event => {event.preventDefault()
           
           
          }}
          // defaultMenuIsOpen={defaultMenuIsOpen}
          onFocus={() => {
            setInputClass("form-group floating-input")
          }}
         onBlur={value => {
          if(selectValue != ''){
            setInputClass("form-group floating-input")
          } else {
            setInputClass("form-group")
          }
          }}
        // onInputChange={() => {
        //   input.onChange(input.value);
        // }}
        // onBlur={() => {
        //   input.onBlur(input.value);
        // }}
        />
            {label && <label>{label}</label>}
        {validationSpan}
      </div>

      {/* <select className="form-control" {...input} {...config}>
        {options.map(option => (
          <option value={option}>{option}</option>
        ))}
      </select> */}
    </>
  );
};
