import React from "react";
import { Field } from "redux-form";
const {
  defaultConfig: { LOCATION },
} = require(`../../../../config/default`);
const { STRINGS } = require(`../../../../shared/constants/${LOCATION}/strings`);

const { Input } = require(`../../../../components/atoms/input`);
const { TextArea } = require(`../../../../components/atoms/text-area`);
const { Form } = require(`../../../../components/atoms/form`);

export const RenderForm = ({ handleSubmit = () => {}, submit, data, set_data, errors, set_errors }) => {
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-md-12">
          <label>{STRINGS.TITLE}</label>
          <Field
            name={STRINGS.TITLE}
            component={Input}
            placeholder={"Title"}
            type={"text"}
            onChange={(event) => {
              set_data({ ...data, title: event.target.value });
              set_errors({ ...errors, title: "" });
            }}
          />
          <span className="text-danger">{errors && errors.title}</span>
        </div>

        <div className="col-md-12">
          <label>{STRINGS.BODY} </label>
          <Field
            name={STRINGS.BODY}
            component={TextArea}
            placeholder={"Body"}
            rows={13}
            type={"text"}
            onChange={(event) => {
              set_data({ ...data, body: event.target.value });
              set_errors({ ...errors, body: "" });
            }}
          />
          <span className="text-danger">{errors && errors.body}</span>
        </div>
      </div>
    </Form>
  );
};
