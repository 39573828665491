const { STRINGS } = require(`../../../../shared/constants/us/strings`);
const { VALIDATION_MESSAGES, EMAIL_REGX } = require(`../../../../shared/constants`)

const announcementValidator = values => {
  const errors = {};
  if (!values['announcement']) {
    errors['announcement'] =
      VALIDATION_MESSAGES.ANNOUCEMENT_REQUIRED;
  } 
  if (!values['redirect']) {
    errors['redirect'] =
      VALIDATION_MESSAGES.REDIRECT_REQUIRED;
  }
  if (!values['answer']) {
    errors['answer'] =
      VALIDATION_MESSAGES.ANSWER_REQUIRED;
  }
  if (!values['announcementDuration']) {
    errors['announcementDuration'] =
      VALIDATION_MESSAGES.DURATION_REQUIRED;
  }
  return errors;
};

export default announcementValidator;

