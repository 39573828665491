export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const ADD_ANNOUNCEMENT_DATA = "ADD_ANNOUNCEMENT_DATA";
export const GET_ANNOUNCEMENT_LISTING = "GET_ANNOUNCEMENT_LISTING";
export const DELETE_ANNOUNCMENT_DATA = "DELETE_ANNOUNCMENT_DATA"
export const EDIT_ANNOUNCMENT_DATA = "EDIT_ANNOUNCMENT_DATA"
export const addAnnouncement = (data, success, failure) => {
    return {
      type: ADD_ANNOUNCEMENT,
      data,
      success,
      failure,
    };
  };

  export const addAnnouncementData = (data) => {
    return {
      type: ADD_ANNOUNCEMENT_DATA,
      data
    };
  };

  export const getAnnouncementListing = (data) => {
    return {
      type: GET_ANNOUNCEMENT_LISTING,
      data
    };
  };
  export const deleteAnnouncement = (data,success,failure) => {
    return {
      type: DELETE_ANNOUNCMENT_DATA,
      data,success,failure
    };
  };
  export const editAnnouncement = (data,success,failure) => {
    return {
      type: EDIT_ANNOUNCMENT_DATA,
      data,success,failure
    };
  };