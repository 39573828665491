import React,{useState,useEffect} from "react";

export const TextArea = ({ value, rows, input, label, meta: { touched, error }, config, placeholder, widthStyle }) => {
  widthStyle = widthStyle ? widthStyle : "";
  const validationSpan = touched && error ? <span className="error_msg text-danger">{error}</span> : null;
  const [inputClass,setInputClass]  = useState("form-group")
  useEffect(() =>{
    if(input.value != ''){
      setInputClass("form-group floating-input")
    } else {
      setInputClass("form-group")
    }
  },[input.value])
  return (
    // <div className={widthStyle}>
    <div className={inputClass}>
    
      <textarea
        className="form-control"
        rows={rows}
        {...input}
        style={{ color: "black" }}
        placeholder={placeholder}
        {...config}
        onBlur={event => {event.preventDefault()
        
        }}
        onFocus={() => {
          setInputClass("form-group floating-input")
        }}
      />
        {label && <label>{label}</label>}
      {validationSpan}
    </div>
    // </div>
  );
};
