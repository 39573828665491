import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./styles.scss";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { reduxForm, reset } from "redux-form";
import { CustomPagination } from "../../../../components/atoms/pagination";
const {
  defaultConfig: { LOCATION },
} = require(`../../../../config/default`);

const { DecisionPopup } = require(`../../../../components/atoms/decision-popup`);

const { STRINGS } = require(`../../../../shared/constants/${LOCATION}/strings`);
const { PAGE_TITLES } = require(`../../../../shared/constants`);
const { SnackbarWrapper } = require(`../../../../components/molecules/snackbar-wrapper`);
const { RenderForm } = require(`./form`);
const { VALIDATION_MESSAGES } = require(`../../../../shared/constants`);
moment.tz.setDefault("America/New_York");

let columns = [{ name: "Pic" }, { name: "Name" }, { name: "Email" }];

const WatchPartyUsers = ({
  history,
  getWatchPartyUsers,
  sendNotificationToUsers,
  handleSubmit = () => {},
  resetForm,
}) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const [noData, toggleNoData] = useState(false);
  const [users, updateUsers] = useState([]);
  const [usersPageLimit, updateUsersPageLimit] = useState(STRINGS.SHOW_LIMIT);
  const [currentPageIndex, updateCurrentPageIndex] = useState(0);
  const [totalUsers, updateTotalUsers] = useState(0);
  const [popupVisible, setPopVisible] = useState(false);
  const [data, set_data] = useState({ title: "", body: "" });
  const [errors, set_errors] = useState({ title: "", body: "" });
  const [id, set_id] = useState("");
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackBarData] = useState({
    variant: "",
    message: "",
  });
  let query = useQuery();
  useEffect(() => {
    updateCurrentPageIndex(0);
    _getJoinedUsers();
  }, [usersPageLimit]);
  const _getJoinedUsers = (skip = 0) => {
    let path = history.location.pathname;
    let watchPartyId = query.get("watch_party_id");
    set_id(watchPartyId);
    if (path == "/watch-party-users" && watchPartyId) {
      toggleNoData(false);
      getWatchPartyUsers(
        {
          watchPartyId,
          limit: usersPageLimit,
          skip,
        },
        (data) => {
          updateUsers(data.users || []);
          updateTotalUsers(data.numberOfDocuments || 0);
          console.log(data);
        },
        () => {}
      );
    } else {
      toggleNoData(true);
    }
  };
  const validator = () => {
    if (!data.title) {
      set_errors({ ...errors, title: VALIDATION_MESSAGES.TITLE_REQUIRED });
      return false;
    } else if (data.title) {
      set_errors({ ...errors, title: "" });
    }

    if (!data.body) {
      set_errors({ ...errors, body: VALIDATION_MESSAGES.BODY_REQUIRED });
      return false;
    } else if (data.body) {
      set_errors({ ...errors, body: "" });
    }

    return true;
  };

  const submit = (values) => {
    let result = validator();
    if (result) {
      let postData = {
        title: data.title,
        body: data.body,
        watchPartyId: id,
      };
      sendNotificationToUsers(
        postData,
        (response) => {
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg,
          });
          setOpenSnackbar(true);
          setPopVisible(false);
          set_data({ title: "", body: "" });
          set_errors({ title: "", body: "" });
          resetForm();
        },
        (error) => {
          setSnackBarData({
            variant: error.status ? "success" : "error",
            message: error.msg,
          });
          setOpenSnackbar(true);
        }
      );
    } else {
      console.log("error");
    }
  };
  return (
    <div className="container">
      <SnackbarWrapper
        visible={openSnackBar}
        onClose={() => setOpenSnackbar(false)}
        variant={snackbarData.variant}
        message={snackbarData.message}
      />
      {noData ? (
        <div>Watch party id not found.</div>
      ) : (
        <>
          <div>
            <div className="text-right col-md-12 mt-3">
              {users.length ? (
                <button
                  className="btn btn-lg btn-primary btn_group btn-radius"
                  onClick={() => {
                    setPopVisible(true);
                  }}
                >
                  {PAGE_TITLES.SEND_NOTIFICATION}
                </button>
              ) : null}
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  {!!users.length &&
                    columns.map((party) => {
                      return <th key={party.name}>{party.name}</th>;
                    })}
                </thead>
                <tbody>
                  {users.length ? (
                    <>
                      {users.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                style={{ width: "40px", height: "40px", borderRadius: "50px" }}
                                alt=""
                                src={
                                  user.pictureUrl
                                    ? user.pictureUrl
                                    : require("../../../../assets/img/icons/profile-user.png")
                                }
                              />
                            </td>
                            <td>
                              <div className="input_field">{user.firstName}</div>
                            </td>
                            <td>
                              <div className="input_field">{user.email}</div>
                            </td>
                          </tr>
                        );
                      })}
                      <DecisionPopup //modal for notification content
                        modalVisibility={popupVisible}
                        dialogContent={
                          <RenderForm
                            submit={submit}
                            data={data}
                            set_data={set_data}
                            errors={errors}
                            set_errors={set_errors}
                            handleSubmit={handleSubmit}
                          />
                        }
                        dialogTitle={STRINGS.NOTIFICATIONS_CONTENT}
                        confirmButtonTitle={STRINGS.SEND}
                        rejectButtonTitle={STRINGS.CANCEL}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={() => {
                          submit();
                        }}
                        onRejection={() => {
                          setPopVisible(false);
                          set_data({ title: "", body: "" });
                          set_errors({ title: "", body: "" });
                          resetForm();
                        }}
                      />
                    </>
                  ) : (
                    "No user found in this Watch Party."
                  )}
                </tbody>
              </table>
            </div>

            {!!users.length && (
              <CustomPagination
                limit={usersPageLimit}
                totalPages={totalUsers}
                onChangePageLimit={(val) => updateUsersPageLimit(val)}
                itemsCount={users.length}
                currentPage={currentPageIndex + 1}
                onPageChange={(value) => {
                  _getJoinedUsers(value.selected * usersPageLimit);
                  updateCurrentPageIndex(value.selected);
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: () => dispatch(reset("notificationform")),
  };
};

const UserScreen = reduxForm({
  form: "notificationform",
})(WatchPartyUsers);
export const Screen = connect(mapStateToProps, mapDispatchToProps)(UserScreen);
