import { connect } from "react-redux";
import { Screen } from "./screen";

const { getWatchPartyUsers, sendNotificationToUsers } = require(`../../../../redux/actions`);

const mapStateToProps = (state) => {
  return {
    userToken: state.CommonReducer.userToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWatchPartyUsers: (payload, success, failure) => dispatch(getWatchPartyUsers(payload, success, failure)),
    sendNotificationToUsers: (data, success, failure) => dispatch(sendNotificationToUsers(data, success, failure)),
  };
};
export const WatchPartyUsersScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);
