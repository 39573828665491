import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from '@material-ui/core/TextField';

import "./style.scss";
const {IbarForm} = require('../../../views/us/1.0/add-watch-party/ibarDialog')
const {TriviaForm} = require('../../../views/us/1.0/add-watch-party/triviaDialog')
export const DecisionPopup = ({
  modalVisibility,
  dialogContent,
  dialogTitle,
  confirmButtonTitle = "Confirm",
  rejectButtonTitle = "Cancel",
  toggleDialogModal = () => {},
  onConfirmation = () => {},
  onRejection = () => {},
  dialogFields,
  watchPartyId,
  dialogTriviaFields
}) => {

  return (
    <div>
      <Dialog
        open={modalVisibility}
        onClose={toggleDialogModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
            {dialogFields ? <IbarForm onConfirmation={onConfirmation} watchPartyId={watchPartyId} dialogFields={dialogFields} onRejection={onRejection} /> : 
            dialogTriviaFields ? <TriviaForm dialogTriviaFields={dialogTriviaFields} onConfirmation={onConfirmation} watchPartyId={watchPartyId} onRejection={onRejection}  /> : null}
        </DialogContent>
        <DialogActions>
          {!!confirmButtonTitle && (
            dialogFields || dialogTriviaFields ? null : <Button onClick={onConfirmation} color="primary">
              {confirmButtonTitle}
            </Button>
          )}
          {dialogFields || dialogTriviaFields? null : <Button onClick={onRejection} className={"button-outline"} color="primary">
            {rejectButtonTitle}
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};
