import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { reduxForm, Field, change as onChangeForm } from "redux-form";
import './style.scss'
import { connect } from "react-redux";
import triviaValidator from './triviaValidator'
const { onSubmitFail } = require(`../../../../helpers`);
const { Form } = require(`../../../../components/atoms/form`);
const {
  defaultConfig: { LOCATION },
} = require(`../../../../config/default`);
const { Input } = require(`../../../../components/atoms/input`);
const { STRINGS } = require(`../../../../shared/constants/${LOCATION}/strings`);
const { InputSubmit } = require(`../../../../components/atoms/input-submit`);
const { addAnnouncement,editAnnouncement } = require(`../../../../redux/actions`);

const TriviaScreen = (props) => {
  const [fields, setFields] = useState({
    announcement: '',
    answer: '',
    announcementDuration: '',
    answerDuration: ''
  })
  const onsubmit = (e) => {
    e.preventDefault()
    if(fields.announcement != '' && fields.answerDuration != ''&& fields.answer != '' && fields.announcementDuration != ''){
    let postdata = { ...fields, type: 2, watchpartyId: props.watchPartyId,   announcementDuration: fields.announcementDuration,
    answerDuration: fields.answerDuration}
    console.log(postdata)
    if(props && props.dialogTriviaFields && props.dialogTriviaFields.edit && props.dialogTriviaFields.edit.hasOwnProperty('_id')){
      props.editAnnouncement({...postdata,id: props.dialogTriviaFields.edit._id}, (success) => {
        props.onConfirmation(success)
      }, (failure) => {
        props.onConfirmation(failure)
      })
    } else {
      props.addAnnouncement(postdata, (success) => {
        props.onConfirmation(success)
      }, (failure) => {
        props.onConfirmation(failure)
      })
    }
    }
  }
  const onChangeField = (type, value) => {
    setFields({ ...fields, [type]: value })
  }

  useEffect(() => {
    console.log(props)
   if(props && props.dialogTriviaFields && props.dialogTriviaFields.edit && props.dialogTriviaFields.edit.hasOwnProperty('_id')){
     setFields( {announcement: props.dialogTriviaFields.edit.content.text,
      answer: props.dialogTriviaFields.edit.answer.text,
     announcementDuration: props.dialogTriviaFields.edit.content.duration,
     answerDuration: props.dialogTriviaFields.edit.answer.duration,
    })
   }
  },[props && props.dialogTriviaFields])

  return (
    <div className="container">
      <Form onSubmit={onsubmit}>
        <Field
          name={'announcement'}
          component={Input}
          label={"Question"}

          type={"text"}
          onChange={(event) => onChangeField("announcement", event.target.value)}
          config={{
            value: fields.announcement ? fields.announcement : null,
          }}
        />
        <Field
          name={"answer"}
          component={Input}
          label={"Correct Answer"}

          type={"text"}
          onChange={(event) => onChangeField("answer", event.target.value)}
          config={{
            value: fields.answer ? fields.answer : null,
          }}
        />
        <Field
          name={"announcementDuration"}
          component={Input}
          label={"Question Duration(secs) multiples of 10"}

          type={"number"}
          onChange={(event) => onChangeField("announcementDuration", event.target.value)}
          config={{
            value: fields.announcementDuration ? fields.announcementDuration : null, min:0, step:10
          }}
        />
        <Field
          name={"answerDuration"}
          component={Input}
          label={"Answer Duration(secs) multiples of 10"}

          type={"number"}
          onChange={(event) => onChangeField("answerDuration", event.target.value)}
          config={{
            value: fields.answerDuration ? fields.answerDuration : null, min:0, step:10
          }}
        />
        <InputSubmit
          buttonLabel={
            'Save'
          }
          color="primary"
        />
        <Button onClick={props.onRejection} className={"button-outline"} color="primary">
          {'Cancel'}
        </Button>
      </Form>

    </div>
  )
}
const reduxFormFunction = reduxForm({
  form: "TriviaScreen",
  fields: ['email', 'password'],
  onSubmitFail,
  validate: triviaValidator,
  enableReinitialize: true
})(TriviaScreen);
const mapStateToProps = (state, props) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnnouncement: (data, success, failure) => dispatch(addAnnouncement(data, success, failure)),
    editAnnouncement: (data, success, failure) => dispatch(editAnnouncement(data, success, failure)),
  };
};

export const TriviaForm = connect(mapStateToProps, mapDispatchToProps)(reduxFormFunction);