import moment from "moment";
const { STRINGS } = require(`../../../../shared/constants/us/strings`);
const { VALIDATION_MESSAGES, NAME_REGX } = require(`../../../../shared/constants`);
const { calculateCurrentTimeInEst } = require(`../../../../helpers`);

const validator = (values) => {
  const errors = {};

  if (!values[STRINGS.SHOW_NAME]) {
    errors[STRINGS.SHOW_NAME] = VALIDATION_MESSAGES.SHOW_NAME_REQUIRED;
  }
  //  else if (
  //     !NAME_REGX.test(
  //         values[STRINGS.SHOW_NAME]
  //     )) {
  //     errors[STRINGS.SHOW_NAME] = VALIDATION_MESSAGES.NAME_VALIDATION;
  else if (values) {
    if (!values[STRINGS.SHOW_NAME].trim()) errors[STRINGS.SHOW_NAME] = VALIDATION_MESSAGES.SHOW_NAME_REQUIRED;
  }
  if (values[STRINGS.INVITE_TEXT_NAME]) {
    if (!values[STRINGS.INVITE_TEXT_NAME].trim())
      errors[STRINGS.INVITE_TEXT_NAME] = VALIDATION_MESSAGES.INVITE_TEXT_REQUIRED;
  }
  if (values[STRINGS.SHARE_DIALOG_TITLE_NAME]) {
    if (!values[STRINGS.SHARE_DIALOG_TITLE_NAME].trim())
      errors[STRINGS.SHARE_DIALOG_TITLE_NAME] = VALIDATION_MESSAGES.SHARE_DIALOG_TITLE_NAME_REQUIRED;
  }
  if (values[STRINGS.SHARE_DIALOG_TITLE_URL_NAME]) {
    if (!values[STRINGS.SHARE_DIALOG_TITLE_URL_NAME].trim())
      errors[STRINGS.SHARE_DIALOG_TITLE_URL_NAME] = VALIDATION_MESSAGES.SHARE_DIALOG_TITLE_URL_NAME_REQUIRED;
  }
  if (values[STRINGS.HOST_NAME] && !NAME_REGX.test(values[STRINGS.HOST_NAME])) {
    errors[STRINGS.HOST_NAME] = VALIDATION_MESSAGES.NAME_VALIDATION;
  }
  if (!values[STRINGS.PLATFORM_NAME]) {
    errors[STRINGS.PLATFORM_NAME] = VALIDATION_MESSAGES.PLATFORM_REQUIRED;
  }
  if (values[STRINGS.SPORTS_NAME] && values[STRINGS.SPORTS_NAME].value && !values[STRINGS.LEAGUE_NAME]) {
    errors[STRINGS.LEAGUE_NAME] = VALIDATION_MESSAGES.LEAGUE_REQUIRED;
  }
  if (!values[STRINGS.SPORTS_NAME]) {
    errors[STRINGS.SPORTS_NAME] = VALIDATION_MESSAGES.SPORTS_REQUIRED;
  }

  if (!values[STRINGS.END_TIME]) {
    errors[STRINGS.END_TIME] = VALIDATION_MESSAGES.END_TIME_REQUIRED;
  }
  if (!values[STRINGS.START_TIME]) {
    errors[STRINGS.START_TIME] = VALIDATION_MESSAGES.START_TIME_REQUIRED;
  }
  if (values[STRINGS.START_TIME] && values[STRINGS.END_TIME]) {
    if (values[STRINGS.END_TIME] < values[STRINGS.START_TIME]) {
      errors[STRINGS.END_TIME] = VALIDATION_MESSAGES.START_TIME_SHOULD_BE_AHEAD;
    } else {
      var diff = (values[STRINGS.START_TIME].getTime() - values[STRINGS.END_TIME].getTime()) / 1000;
      diff /= 60;
      let timediff = Math.abs(Math.round(diff));

      if (timediff == 0) {
        errors[STRINGS.END_TIME] = VALIDATION_MESSAGES.SAME_TIME_VALIDATION;
      }
    }
  }

  if (!values[STRINGS.START_TIME] && values[STRINGS.END_TIME]) {
    errors[STRINGS.END_TIME] = VALIDATION_MESSAGES.SELECT_START_TIME_FIRST;
  }

  let currTime;

  currTime = calculateCurrentTimeInEst();

  // if (values[STRINGS.START_TIME]) {
  //     let pickedTime = values[STRINGS.START_TIME]

  //     if (new Date(pickedTime) < new Date(currTime)) {
  //         errors[STRINGS.START_TIME] =
  //             VALIDATION_MESSAGES.TIME_SHOUDLD_NOT_BE_IN_PAST;
  //     }
  // }
  if (values[STRINGS.END_TIME]) {
    let pickedTime = values[STRINGS.END_TIME];
    if (new Date(pickedTime) < new Date(currTime)) {
      errors[STRINGS.END_TIME] = VALIDATION_MESSAGES.END_TIME_SHOUDLD_NOT_BE_IN_PAST;
    }
  }
  if (values[STRINGS.SET_PASSWORD_NAME]) {
    if (!(values[STRINGS.SET_PASSWORD_NAME].length >= 5 && values[STRINGS.SET_PASSWORD_NAME].length < 11))
      errors[STRINGS.SET_PASSWORD_NAME] = VALIDATION_MESSAGES.PASSWORD_VALIDATION;
  }

  return errors;
};

export default validator;
