export const STRINGS = {
  EMAIL_INPUT_NAME: "email",
  FIRST_NAME_INPUT: "firstName",
  LAST_NAME_INPUT: "lastName",
  USERNAME_INPUT: "username",
  PHONE_INPUT: "phone",
  ADDRESS_INPUT: "address",
  TIME_ZONE_INPUT: "timezone",
  AGE_INPUT: "age",
  EMAIL_REQUIRED: "Email is required.",
  PASSWORD_VALIDATION: "Password can be 5-10 characters",
  EMAIL_PLACEHOLDER: "Email",
  PASSWORD_INPUT_NAME: "password",
  USER_ROLE: "role",
  PASSWORD_REQUIRED: "Password is required.",
  SHOW_NAME: "show",
  SHOW: "Show",
  SHARE_DIALOG_TITLE_LABEL: "Share Dialog Title",
  SHARE_DIALOG_TITLE_NAME: "dialogTitle",
  SHARE_DIALOG_TITLE_URL_LABEL: "Share Dialog Body",
  SHARE_DIALOG_TITLE_URL_NAME: "dialogUrl",
  INVITE_TEXT_LABEL: "Invite Text",
  INVITE_TEXT_REQUIRED: "Invite Text is Required",
  SHARE_DIALOG_TITLE_NAME_REQUIRED: "Share Dialog Title is Required",
  SHARE_DIALOG_TITLE_URL_NAME_REQUIRED: "Share Dialog Title Url is Required",
  INVITE_TEXT_NAME: "inviteText",
  SET_PASSWORD_LABEL: "Password",
  SET_PASSWORD_NAME: "password",
  LEAGUE_NAME: "league",
  HOST: "Host",
  HOST_NAME: "host",
  INSTAGRAM_INPUT_NAME: "instagramUserName",
  INSTAGRAM_USERNAME_REQUIRED: "Instagram username cannot have blank spaces",
  TWITTER_USERNAME_REQUIRED: "Twitter username cannot have blank spaces",
  TWITTER_INPUT_NAME: "twitterUserName",
  PLATFORM_NAME: "platform",
  PLATFORM: "Platform",
  CONTENT_LENGTH: "contentLength",
  START_TIME: "startTime",
  PICK_DATE: "date",
  END_TIME: "endTime",
  SPORTS_NAME: "sports",
  SPORTS: "Sports",
  EMAIL: "Email",
  PASSWORD: "Password",
  CONTENT_PICTURE: "contentPicture",
  PCITURE_REQUIRED: "Cover Picture is required.",
  SAME_TIME_VALIDATION: "Start time and End time cannot be same.",
  PASSWORD_MINLENGTH: "Password must be of minimum 6 characters.",
  PASSWORD_SUCCESSFUL: "Your password has been changed successfully.",
  EMAIL_RESPONSE_MESSAGE: "Reset password link has been sent to your email address.",
  PASSWORD_RESPONSE_MESSAGE: "Please enter new password below.",
  RESET_PASSWORD: "RESET PASSWORD",
  SELECT_START_TIME_FIRST: "Select Start Date and Time first.",
  START_TIME_SHOULD_BE_AHEAD: "Start Time should be before end time",
  TIME_SHOUDLD_NOT_BE_IN_PAST: "Start Time cannot be in past.",
  END_TIME_SHOUDLD_NOT_BE_IN_PAST: "End Time cannot be in past.",
  LOGIN: "Login",
  GO_TO_LOGIN: "Go to Login",
  PASSWORD_LABEL: "Password",
  NAME_VALIDATION: "Name cannot have numbers and special characters.",
  EMAIL_LABEL: "Email",
  VALUE_CANNOT_BE_ONLY_SPACES: "Value cannot be only spaces.",
  PASSWORD_PLACEHOLDER: "Password",
  FORGOT_PASSWORD: "Forgot Password?",
  BUTTON_LABEL_LOGIN: "Sign In",
  EMAIL_INVALID: "Email is invalid.",
  LOG_IN: "Log In?",
  FORGOT_LOGIN: "Log in",
  SHOW_NAME_REQUIRED: "Show Name is required.",
  FIRST_NAME_REQUIRED: "First Name is required.",
  LAST_NAME_REQUIRED: "Last Name is required.",
  USER_NAME_REQUIRED: "Username is required.",
  PHONE_REQUIRED: "Phone No is required.",
  ADDRESS_REQUIRED: "Address is required.",
  TIMEZONE_REQUIRED: "Timezone is required.",
  AGE_REQUIRED: "Age is required.",
  AGE_VALIDATION: "Age should be minimum 13 years",
  MAX_AGE_VALIDATION: "User age must be less than 150",
  PHONE_VALIDATION: "Phone no is not valid.",
  HOST_REQUIRED: "Host is required.",
  SPORTS_REQUIRED: "Sport is required.",
  LEAGUE_REQUIRED: "League is required.",
  PLATFORM_REQUIRED: "Platform is required.",
  DATE_REQUIRED: "Show Date is required.",
  START_TIME_REQUIRED: "Start Time is required.",
  END_TIME_REQUIRED: "End Time is required.",
  CONTENT_REQUIRED: "Content Length is required.",
  LOGOUT: "Logout",
  HOME: "Home",
  LOGOUT_CONTENT: "Are you sure you want to logout?",
  CONGRATULATIONS: "Congratulations!",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  NAME: "Name",
  NO_ADMINS_FOUND: "No Admins found",
  NO_USERS_FOUND: "No Users found",
  LOG_OUT: "Log Out",
  SUCCESS: "success",
  ADMINS: "Admins",
  ERROR: "error",
  SHOW_LIMIT: 10,
  NOTIFICATIONS_CONTENT: "Notification Content",
  TITLE: "Title",
  TITLE_REQUIRED: "Title Required",
  BODY_REQUIRED: "Body Required",
  SEND: "Send",
  BODY: "Body",
  SHOWN: "Shown",
  SEND_EMAIL: "Send Email",
  FIRST_NAME_PLACEHOLDER: "First Name",
  LAST_NAME_PLACEHOLDER: "Last Name",
  PHONE_PLACEHOLDER: "Phone No",
  ADDRESS_PLACEHOLDER: "HomeTown",
  TIME_ZONE_PLACEHOLDER: "Time Zone",
  USERNAME_PLACEHOLDER: "User Name",
  AGE_PLACEHOLDER: "Age",
  LEAGUE: "League",
  START: "Start Time(EST).",
  END: "End Time(EST).",
  CONTENT: "Content Length",
  WATCH_PARTY_VIDEO: "Watch Party Video",
  DEFAULT_KEYWORDS: "Default Keywords For Chat Gifs",
  USER_GIFS: "Select User Gifs",
  ERROR_FOR_MIN_LENGTH_GIFS: "Please Select atleast four or zero user gif's",
  ANNOUCEMENT_REQUIRED: "Announcement  is Required.",
  REDIRECT_REQUIRED: "Redirect  is Required.",
  DURATION_REQUIRED: "Duration  is Required.",
  ANSWER_REQUIRED: "Answer is Required.",
  ANSWER_DURATION_REQUIRED: "Answer duration is Required",
};
